const Header = () => {
    return (
        <section>
            <div className="text-center">
                <img alt='DETSYS' src="/static/despachelogo.png" />
                <h1>DESPSYS</h1>
            </div>
        </section>
    );
};

export default Header;