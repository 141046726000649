import { Box, TextField } from '@mui/material';
import ColorButton from '../Buttons/ColorButton';
import React, { useContext, useReducer, useEffect } from 'react';
import { NumbersOnly } from '../Utils/IntegerOnly';
import { PhoneMask, CnpfMask, CepMask } from '../Utils/MaskedInput';
import DefaultModal from '../Utils/DefaultModal';
import { lockedReducer } from '../Utils/reducers';
import { externalComponent } from '../AppRoutes';
import './styles.css'
import { api } from '../../services/api';
import toast from 'react-hot-toast';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';

const originURL = "https://www.nfe.fazenda.gov.br"
const originHref = "/portal/consultaRecaptcha.aspx"
const originResumo = "/portal/consultaResumo.aspx"
const receitaURL = originURL + originHref
const resumoURL = originURL + originResumo
var eventReceived = false
var consultReceived = false
var childWindow = null

const url = '/serviceorder/'
const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}
const submit = async (state, data, setLoader, handleClose, reloadData) => {
    setLoader(true)

    let res = null
    if (data.id)
        res = await api.patch(`${url}${data.id}/`, state)
    // if (data.id)
    //     res = await api.get(`${url}${data.id}/generate/`, state)

    else
        res = await api.post(url, state)



    setLoader(false)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Ordem de serviço ${res.status === 201 ? 'adicionada' : 'editada'} com sucesso`)
        reloadData()
        handleClose()

        return;
    }

    toast.error(`error`);
}

const EditServiceOrderModal = ({ reloadData, readOnly, handleClose, data }) => {
    const { setLoader } = useContext(externalComponent)
    const [state, setState] = useReducer(lockedReducer, {
        nfemodal: null,
        editSchedule: null,
        expanded: false,
        service_number: '',
        client: '',
        chassi: '',
        renavam: '',
        resp_financial: '',
        owner: '',
        color: '',
        service: '',
        model_year: '',
        fab_year: '',
        brand_model: '',
        fuel: '',
        exercise: '',
        process_control: '',
        minuta: '',
        observations: '',
        obligation: '',
        vehicle_city: '',
        license_plate: '',
        values: '',
        inputValue: '',
        id: data?.id || 0,
        ...data
    })

    const loadDataClient = async (id, setState, setLoader) => {
        setLoader(true)

        const res = await api.get(url + `${id}/`)
        setLoader(false)
        if (res.status === 200) {
            setState({ ...res.data })
            return
        }
    }

    const handleExpansion = () => {
        setState({ expanded: (prevExpanded) => !prevExpanded });
    };

    const content = (<>
        <Accordion
            defaultExpanded
            onChange={handleExpansion}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography>Cliente</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box className="item-content-modal noborder">
                    <Box className="item-profile-row">
                        <TextField
                            id="name"
                            type="text"
                            label="Nome"
                            disabled={readOnly}
                            size='small'
                            value={state.client?.name}
                            onChange={(e) => setState({ client: { ...state.client, name: e.target.value } })}
                            fullWidth={false}
                            inputProps={{
                                maxLength: 100
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="email"
                            type="text"
                            label="Email"
                            disabled={readOnly}
                            size='small'
                            value={state.client?.email}
                            onChange={(e) => setState({ client: { ...state.client, email: e.target.value } })}
                            fullWidth={false}
                            inputProps={{
                                maxLength: 50
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="phone_number"
                            type="text"
                            label="Telefone"
                            disabled={readOnly}
                            size='small'
                            value={state.client?.phone_number}
                            onChange={(e) => setState({ client: { ...state.client, phone_number: NumbersOnly(e.target.value) } })}
                            fullWidth={false}
                            InputProps={{
                                inputComponent: PhoneMask
                            }}
                        />
                        <TextField
                            sx={{ flex: 1 }}
                            id="phone_cell"
                            type="text"
                            label="Celular"
                            disabled={readOnly}
                            size='small'
                            value={state.client?.phone_cell}
                            onChange={(e) => setState({ client: { ...state.client, phone_cell: NumbersOnly(e.target.value) } })}
                            fullWidth={false}
                            InputProps={{
                                inputComponent: PhoneMask
                            }}
                        />
                        <TextField
                            sx={{ flex: 1 }}
                            id="phone_commercial"
                            type="text"
                            label="Telefone"
                            disabled={readOnly}
                            size='small'
                            value={state.client?.phone_commercial}
                            onChange={(e) => setState({ client: { ...state.client, phone_commercial: NumbersOnly(e.target.value) } })}
                            fullWidth={false}
                            InputProps={{
                                inputComponent: PhoneMask
                            }}
                        />
                    </Box>
                    <Box className='item-profile-row'>
                        <TextField
                            id="cpf_cnpj"
                            type="text"
                            label="CPF/CNPJ"
                            disabled={readOnly}
                            size='small'
                            value={state.client?.cpf_cnpj}
                            onChange={(e) => setState({ client: { ...state.client, cpf_cnpj: NumbersOnly(e.target.value) } })}
                            fullWidth={false}
                            InputProps={{
                                inputComponent: CnpfMask
                            }}
                        />

                        <TextField
                            id="rg"
                            type="text"
                            label="RG"
                            disabled={readOnly}
                            size='small'
                            value={state.client?.rg}
                            onChange={(e) => setState({ client: { ...state.client, rg: e.target.value } })}
                            fullWidth={false}
                            inputProps={{
                                maxLength: 25
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField
                            sx={{ flex: .5 }}
                            id="cep"
                            type="text"
                            label="CEP"
                            disabled={readOnly}
                            value={state.client?.cep}
                            onChange={(e) => setState({ client: { ...state.client, cep: NumbersOnly(e.target.value) } })}
                            fullWidth={false}
                            size='small'
                            InputProps={{
                                inputComponent: CepMask
                            }}
                        />
                        <TextField
                            sx={{ flex: 0.8 }}
                            type="text"
                            id="complement"
                            disabled={readOnly}
                            label="Complemento"
                            value={state.client?.complement}
                            size='small'
                            fullWidth={false}
                            onChange={(e) => setState({ client: { ...state.client, complement: e.target.value } })}
                            inputProps={{
                                maxLength: 50
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="street"
                            type="text"
                            label="Rua"
                            size='small'
                            disabled={readOnly}
                            value={state.client?.street}
                            onChange={(e) => setState({ client: { ...state.client, street: e.target.value } })}
                            fullWidth={false}
                            inputProps={{
                                maxLength: 100
                            }}
                        />
                        <TextField
                            sx={{ flex: .2 }}
                            id="street_number"
                            type="text"
                            label="Número"
                            disabled={readOnly}
                            size='small'
                            value={state.client?.street_number}
                            onChange={(e) => setState({ client: { ...state.client, street_number: e.target.value } })}
                            fullWidth={false}
                            inputProps={{
                                maxLength: 50
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }} >
                        <TextField
                            sx={{ flex: 1 }}
                            id="neighborhood"
                            type="text"
                            label="Bairro"
                            disabled={readOnly}
                            value={state.client?.neighborhood}
                            onChange={(e) => setState({ client: { ...state.client, neighborhood: e.target.value } })}
                            fullWidth={false}
                            size='small'
                            inputProps={{
                                maxLength: 100
                            }}
                        />
                        <TextField
                            sx={{ flex: 1 }}
                            id="city"
                            type="text"
                            label="Cidade"
                            disabled={readOnly}
                            value={state.client?.city}
                            onChange={(e) => setState({ client: { ...state.client, city: e.target.value } })}
                            fullWidth={false}
                            size='small'
                            inputProps={{
                                maxLength: 100
                            }}
                        />
                        <TextField
                            sx={{ flex: .2 }}
                            id="uf"
                            type="text"
                            label="UF"
                            disabled={readOnly}
                            value={state.client?.uf}
                            onChange={(e) => setState({ client: { ...state.client, uf: e.target.value } })}
                            fullWidth={false}
                            size='small'
                            inputProps={{
                                maxLength: 100
                            }}
                        />
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <Typography>Veículo</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <Box sx={{ marginBottom: '2rem' }} className="item-content-modal noborder">
                        <Box className="item-content-modal noborder">
                            <TextField
                                sx={{ flex: .5 }}
                                id="owner"
                                type="text"
                                label="Proprietário"
                                disabled={readOnly}
                                value={state.owner}
                                fullWidth={false}
                                onChange={(e) => { setState({ 'owner': e.target.value }) }}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <TextField
                                sx={{ flex: .5 }}
                                id="resp_financial"
                                type="text"
                                label="Responsável financeiro"
                                disabled={readOnly}
                                value={state.resp_financial}
                                onChange={(e) => { setState({ 'resp_financial': e.target.value }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <TextField
                                sx={{ flex: .5 }}
                                id="vehicle_city"
                                type="text"
                                label="Município do veículo"
                                disabled={readOnly}
                                value={state.vehicle_city}
                                onChange={(e) => { setState({ 'vehicle_city': e.target.value }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <Box className="item-profile-row">
                                <TextField
                                    id="license_plate"
                                    type="text"
                                    label="Placa"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.license_plate}
                                    onChange={(e) => { setState({ 'license_plate': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="renavam"
                                    type="text"
                                    label="RENAVAM"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.renavam}
                                    onChange={(e) => { setState({ 'renavam': NumbersOnly(e.target.value) }) }}
                                    fullWidth={false}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="chassi"
                                    type="text"
                                    label="Chassi"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.chassi}
                                    onChange={(e) => { setState({ 'chassi': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Box>


                            <TextField
                                sx={{ flex: 1 }}
                                id="brand_model"
                                type="text"
                                label="Marca/Modelo"
                                disabled={readOnly}
                                size='small'
                                value={state.brand_model}
                                onChange={(e) => { setState({ 'brand_model': e.target.value }) }}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />

                            <TextField
                                sx={{ flex: .7 }}
                                id="color"
                                type="text"
                                label="Cor"
                                disabled={readOnly}
                                size='small'
                                value={state.color}
                                onChange={(e) => { setState({ 'color': e.target.value }) }}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="fab_year"
                                    type="text"
                                    label="Fab/Mod"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.fab_year}
                                    onChange={(e) => { setState({ 'fab_year': NumbersOnly(e.target.value) }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="model_year"
                                    type="text"
                                    label="Modelo"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.model_year}
                                    onChange={(e) => { setState({ 'model_year': NumbersOnly(e.target.value) }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="fuel"
                                    type="text"
                                    label="Combustível"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.fuel}
                                    onChange={(e) => { setState({ 'fuel': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="service"
                                    type="text"
                                    label="Serviço"
                                    disabled={readOnly}
                                    size='small'
                                    value={state.service}
                                    onChange={(e) => { setState({ 'service': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 200
                                    }}
                                />
                                <TextField
                                    sx={{ flex: .5 }}
                                    id="minuta"
                                    type="text"
                                    label="Minuta"
                                    disabled={readOnly}
                                    value={state.minuta}
                                    onChange={(e) => { setState({ 'minuta': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="obligation"
                                    type="text"
                                    label="Obrigação"
                                    size='small'
                                    disabled={readOnly}
                                    value={state.obligation}
                                    onChange={(e) => { setState({ 'obligation': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                                <TextField
                                    sx={{ flex: .5 }}
                                    id="exercise"
                                    type="text"
                                    label="Exercício"
                                    disabled={readOnly}
                                    value={state.exercise}
                                    onChange={(e) => { setState({ 'exercise': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>
                            <TextField
                                sx={{ flex: 1 }}
                                type="text"
                                id="process_control"
                                disabled={readOnly}
                                label="Controle Processo"
                                value={state.process_control}
                                size='small'
                                fullWidth={false}
                                onChange={(e) => { setState({ 'process_control': e.target.value }) }}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <TextField
                                sx={{ flex: 1 }}
                                id="observations"
                                type="text"
                                disabled={readOnly}
                                label="Observações"
                                value={state.observations}
                                size='small'
                                fullWidth={false}
                                onChange={(e) => { setState({ 'observations': e.target.value }) }}
                                inputProps={{
                                    maxLength: 500
                                }}
                            />
                        </Box>
                    </Box>
                </Typography>
            </AccordionDetails>
        </Accordion>
    </>)

    useEffect(() => {
        if (data.id) { loadDataClient(data.id, setState, setLoader) }
    }, [data.id])

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Ordem de serviço`
                }
                handleClose={handleClose}
                content={<>
                    {state.nfemodal &&
                        <NotaFiscalModal
                            data={state.nfemodal}
                            handleClose={() => setState({ nfemodal: null })}
                            reloadData={reloadData}
                            setParent={setState}
                            parent={state}
                        />
                    }
                    <Box className='item-content-modal noborder'>
                        {content}
                    </Box>
                </>}
                action={
                    <>
                        <ColorButton
                            sx={{ width: '50%' }}
                            onClick={() => { setState({ nfemodal: {} }) }}
                        >
                            Buscar usando NF-e
                        </ColorButton>
                        <ColorButton
                            onClick={() => submit(state, data, setLoader, handleClose, reloadData)}
                            sx={{ width: '5rem' }}
                        >
                            Salvar
                        </ColorButton>
                    </>
                }
            />

        </>
    )
}


const NotaFiscalModal = ({ handleClose, data, setLoader, parent, setParent, readOnly }) => {

    const [state, setState] = useReducer(lockedReducer, {
        inputValue: ''

    })

    const applySearch = (inputValue) => {
        eventReceived = false

        childWindow = window.open(receitaURL)

        const data = JSON.stringify({ event_name: 'test-event', value: inputValue });

        setTimeout(() => {
            if (childWindow) {
                childWindow.postMessage(data, receitaURL);
            } else {
                console.error("A childWindow não foi aberta corretamente.");
            }
        }, 500);

        trySendMessage(data, receitaURL, 3, 100, eventReceived);
    }
    const trySendMessage = (data, url, retry, interval, event) => {
        if (retry > 0 && event === false) {
            setTimeout(() => {
                if (childWindow) {
                    childWindow.postMessage(data, url);
                    trySendMessage(data, url, retry - 1, interval, event);
                } else {
                    console.error("childWindow não está disponível.");
                }
            }, interval);
        }
    }
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setState({ 'inputValue': inputValue });
        if (inputValue.length >= 44) {
            applySearch(inputValue, handleClose)
        }
    };

    useEffect(() => {
        const handleWindowMessage = (e) => {
            if (e.origin === originURL) {
                const data = JSON.parse(e.data);

                if (data.event_name === 'eventReceived') {
                    eventReceived = true
                } else if (data.event_name === 'initConsult') {
                    const consultData = JSON.stringify({ event_name: 'consult' });
                    consultReceived = false
                    trySendMessage(consultData, resumoURL, 3, 100, consultReceived);
                } else if (data.event_name === 'consultResult') {
                    const result = data.data

                    setParent({
                        service_number: result["Dados da NF-e"]["Número"],
                        client: {
                            name: result["Dados do Emitente"]["Nome / Razão Social"],
                            email: '',
                            phone_number: result["Dados do Emitente"]["Telefone"],
                            phone_cell: '',
                            phone_commercial: '',
                            cep: result["Dados do Emitente"]["CEP"],
                            street: result["Dados do Emitente"]["Endereço"].replace(/&nbsp;/g, ' ').trim().replace(/\d+\s*$/, '').trim() || '',
                            neighborhood: result["Dados do Emitente"]["Bairro / Distrito"],
                            complement: '',
                            city: result["Dados do Emitente"]["Município"].split('\n').pop().trim() || '',
                            uf: result["Dados do Emitente"]["UF"],
                            street_number: result["Dados do Emitente"]["Endereço"].replace(/&nbsp;/g, ' ').trim().match(/\d+\s*$/)?.[0].trim() || '',
                            cpf_cnpj: result["Dados do Emitente"]["CNPJ"]
                        },
                        chassi: result['Detalhamento Específico dos Veículos Novos']['Chassi do veículo'],
                        renavam: result['Informações adicionais do produto']['Descrição'].split('#').find(parte => parte.trim().startsWith("RENAVAM"))?.split(':')[1].trim(),
                        resp_financial: '',
                        owner: result['Dados do Destinatário']['Nome / Razão Social'],
                        color: result['Dados dos Produtos e Serviços']['Descrição da cor'],
                        service: '',
                        model_year: result['Detalhamento Específico dos Veículos Novos']['Ano Modelo de Fabricação'],
                        fab_year: result['Detalhamento Específico dos Veículos Novos']['Ano de Fabricação'],
                        brand_model: result['Informações adicionais do produto']['Descrição'].split('#')[0].split(' - ')[1].trim(),
                        fuel: result['Informações adicionais do produto']['Descrição'].split('#').find(parte => parte.trim().startsWith("COMBUSTIVEL"))?.split(':')[1].trim(),
                        exercise: '',
                        process_control: '',
                        minuta: '',
                        observations: '',
                        obligation: '',
                        vehicle_city: result['Dados do Destinatário']['Município'].split('\n').pop().trim(),
                        license_plate: '',
                    })

                    consultReceived = true
                    if (childWindow) {
                        childWindow.close();
                        childWindow = null
                        setParent({ nfemodal: null })
                    }
                }
            }
        };

        window.addEventListener('message', handleWindowMessage);

        return () => {
            window.removeEventListener('message', handleWindowMessage);
        };

    }, [childWindow, consultReceived, setLoader, data.id]);


    return (<>
        <DefaultModal
            handleClose={handleClose}
            title={
                `Insira á NF-e`
            }
            content={<>
                <Box className='item-content-modal noborder'>
                    <TextField
                        sx={{ flex: 1 }}
                        id="inputValue"
                        type="text"
                        label="NF-e"
                        disabled={readOnly}
                        size='small'
                        value={state.inputValue}
                        onChange={(e) => handleInputChange(e)}
                        fullWidth={false}
                        inputProps={{
                            maxLength: 50
                        }}
                    />
                </Box>
            </>}
            action={
                <>

                </>
            }
        />

    </>)
}
export default EditServiceOrderModal;