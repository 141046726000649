import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../Utils/reducers'
import { api } from '../../services/api'
import ColorButton from '../Buttons/ColorButton';
import DefaultModal from '../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../AppRoutes';
import { CepMask, CnpfMask, PhoneMask } from '../Utils/MaskedInput';
import { NumbersOnly } from '../Utils/IntegerOnly';
import cepLoad from '../Utils/Cepload';
import { handleCepComplete } from '../Utils/Cepload';

const url = '/client/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose, mode) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Cliente ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        if (mode === 'table')
            getData()
        else
            getData(res.data)
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Cliente não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const EditClientModal = ({ data, handleClose, reloadData, readOnly, mode = 'table' }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        name: '',
        email: '',
        phone_number: '',
        phone_cell: '',
        phone_commercial: '',
        cep: '',
        street: '',
        neighborhood: '',
        complement: '',
        city: '',
        uf: '',
        street_number: '',
        cpf_cnpj: '',
        rg: '',
        ...data,
        id: data?.id || 0,

    })

    const validData = validateData(state)

    const content = (<>
        <Box className="item-profile-row">
            <TextField
                id="name"
                type="text"
                label="Nome"
                disabled={readOnly}
                size='small'
                value={state.name}
                onChange={(e) => { setState({ 'name': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
                sx={{ flex: 1 }}
                id="email"
                type="text"
                label="Email"
                disabled={readOnly}
                size='small'
                value={state.email}
                onChange={(e) => { setState({ 'email': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
                sx={{ flex: 1 }}
                id="phone_number"
                type="text"
                label="Telefone"
                disabled={readOnly}
                size='small'
                value={state.phone_number}
                onChange={(e) => { setState({ 'phone_number': NumbersOnly(e.target.value) }) }}
                fullWidth={false}
                InputProps={{
                    inputComponent: PhoneMask
                }}
            />
            <TextField
                sx={{ flex: 1 }}
                id="phone_cell"
                type="text"
                label="Celular"
                disabled={readOnly}
                size='small'
                value={state.phone_cell}
                onChange={(e) => { setState({ 'phone_cell': NumbersOnly(e.target.value) }) }}
                fullWidth={false}
                InputProps={{
                    inputComponent: PhoneMask
                }}
            />
            <TextField
                sx={{ flex: 1 }}
                id="phone_number"
                type="text"
                label="Telefone"
                disabled={readOnly}
                size='small'
                value={state.phone_number}
                onChange={(e) => { setState({ 'phone_number': NumbersOnly(e.target.value) }) }}
                fullWidth={false}
                InputProps={{
                    inputComponent: PhoneMask
                }}
            />
        </Box>


        <Box className='item-profile-row'>
            <TextField
                id="cpf_cnpj"
                type="text"
                label="CPF/CNPJ"
                disabled={readOnly}
                size='small'
                value={state.cpf_cnpj}
                onChange={(e) => { setState({ 'cpf_cnpj': NumbersOnly(e.target.value) }) }}
                fullWidth={false}
                InputProps={{
                    inputComponent: CnpfMask
                }}
            />

            <TextField
                id="rg"
                type="text"
                label="RG"
                disabled={readOnly}
                size='small'
                value={state.rg}
                onChange={(e) => { setState({ 'rg': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 25
                }}
            />

        </Box>


        <Box sx={{ display: "flex", gap: 1 }}>

            <TextField
                sx={{ flex: .5 }}
                id="cep"
                type="text"
                label="CEP"
                disabled={readOnly}
                value={state.cep}
                onChange={(e) => handleCepComplete(e, setState, state)}
                fullWidth={false}
                size='small'
                InputProps={{
                    inputComponent: CepMask
                }}
            />

            <TextField
                sx={{ flex: 0.8 }}
                type="text"
                id="complement"
                disabled={readOnly}
                label="Complemento"
                value={state.complement}
                size='small'
                fullWidth={false}
                onChange={(e) => { setState({ 'complement': e.target.value }) }}
                inputProps={{
                    maxLength: 50
                }}
            />
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>

            <TextField
                sx={{ flex: 1 }}
                id="street"
                type="text"
                label="Rua"
                size='small'
                disabled={readOnly}
                value={state.street}
                onChange={(e) => { setState({ 'street': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 100
                }}
            />

            <TextField
                sx={{ flex: .2 }}
                id="street_number"
                type="text"
                label="Número"
                disabled={readOnly}
                size='small'
                value={state.street_number}
                onChange={(e) => { setState({ 'street_number': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />
        </Box>



        <Box sx={{ display: 'flex', gap: 1 }} >
            <TextField
                sx={{ flex: 1 }}
                id="neighborhood"
                type="text"
                label="Bairro"
                disabled={readOnly}
                value={state.neighborhood}
                onChange={(e) => { setState({ 'neighborhood': e.target.value }) }}
                fullWidth={false}
                size='small'
                inputProps={{
                    maxLength: 100
                }}
            />

            <TextField
                sx={{ flex: 1 }}
                id="city"
                type="text"
                label="Cidade"
                disabled={readOnly}
                value={state.city}
                onChange={(e) => { setState({ 'city': e.target.value }) }}
                fullWidth={false}
                size='small'
                inputProps={{
                    maxLength: 100
                }}
            />

            <TextField
                sx={{ flex: .2 }}
                id="uf"
                type="text"
                label="UF"
                disabled={readOnly}
                value={state.uf}
                onChange={(e) => { setState({ 'uf': e.target.value }) }}
                fullWidth={false}
                size='small'
                inputProps={{
                    maxLength: 100
                }}
            />
        </Box>


    </>)

    if (readOnly) return content

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Cliente`
                }
                handleClose={handleClose}
                content={<Box className='item-content-modal noborder' >{content}</Box>}
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose, mode)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditClientModal