import React, { useContext, useEffect, useState } from 'react'
import ColorButton from '../Buttons/ColorButton'
import apptheme from '../theme/default'
import { Box } from '@mui/material'
import '../Settings/styles.css'
import DefButton from '../Buttons/defbutton'
import ReplyIcon from '@mui/icons-material/Reply';
import { getSession } from '../../contexts/auth'
import { useWindowSize } from "@uidotdev/usehooks";
import { CalcWidthSize } from '../Settings'

import WorkIcon from '@mui/icons-material/Work';
import PestControlIcon from '@mui/icons-material/PestControl';
import InventoryIcon from '@mui/icons-material/Inventory';
import ConstructionIcon from '@mui/icons-material/Construction';
import PaymentIcon from '@mui/icons-material/Payment';

import Servicos from './Servicos'
import Pragas from './Pragas'
import Produtos from './Produtos'
import Payments from './Formas-de-pagamento'
import Equipamentos from './Equipamentos'
import { externalComponent } from '../AppRoutes'

const MenuList = () => {

    const user = getSession()
    const permissions = user?.permissions

    return ({
        'servicos': {
            title: 'Serviços',
            icon: (<WorkIcon />),
            obj: (<Servicos />),
            permission: (permissions.view_service)
        },
        'pragas': {
            title: 'Pragas',
            icon: (<PestControlIcon />),
            obj: (<Pragas />),
            permission: permissions.view_plague
        },
        'produtos': {
            title: 'Produtos',
            icon: (<InventoryIcon />),
            obj: (<Produtos />),
            permission: (permissions.view_product)
        },
        'equipamentos': {
            title: 'Equipamentos',
            icon: (<ConstructionIcon />),
            obj: (<Equipamentos />),
            permission: (permissions.view_equipment)
        },
        'payments': {
            title: 'Formas de pagamento',
            icon: (<PaymentIcon />),
            obj: (<Payments />),
            permission: (permissions.view_payment)
        },
        'place': { permission: true },
        'place2': { permission: true },
        'place3': { permission: true },
    })
}

const Cadastros = () => {
    const { isMobile } = useContext(externalComponent)
    const [selectedTab, setSelectedTab] = useState()
    const size = useWindowSize();

    const menulist = MenuList()

    const calcSize = CalcWidthSize(size)

    const itens = Object.entries(menulist).filter(([key, value]) => value.permission)

    useEffect(()=>{
        window.backtobase = () => setSelectedTab()
    }, [])

    return (
        <>
            {!selectedTab ?
                <>
                    <Box className='gridBox' sx={{ gridTemplateColumns: `repeat(${calcSize}, minMax(0, 1fr))` }}>
                        {itens.map(([key, value]) => (
                            <ColorButton
                                onClick={() => setSelectedTab(key)}
                                sx={{ height: '8rem', flex: 1, visibility: !value.obj ? 'hidden' : 'visible' }}
                                fColor={apptheme.primaryColor}
                                sColor={apptheme.tertiaryColor}>
                                <Box className='flexbox'>
                                    {value.icon}
                                    {value.title}
                                </Box>
                            </ColorButton>

                        ))}
                    </Box>
                </>
                :
                <>
                    <Box className={`settings-back-button ${isMobile ? '': 'hidden'}`}>
                        <DefButton
                            onClick={() => setSelectedTab()}
                        >
                            <ReplyIcon />
                        </DefButton>
                    </Box>
                    {MenuList()[selectedTab].obj}
                </>
            }

        </>
    )
}

export default Cadastros