import React, { useContext, useReducer, useMemo } from 'react'

import { Box, IconButton, Tooltip } from '@mui/material'
import { api } from '../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../contexts/auth';
import { lockedReducer } from '../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import { externalComponent } from '../AppRoutes';
import EditClientModal from './Edit-client-modal';
import { maskedTable } from '../Utils/MaskedInput';
import Table, { TableHook } from '../Table';
import { PhoneMask, CnpfMask } from '../Utils/MaskedInput';
import { ResponsiveActionBox } from '../Table/mobileTable';

const url = '/client/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const columns = (setState, permissions) => {
    return [
        { field: 'identifier', headerName: 'Identificador', flex: 1, filterType: 'number' },
        { field: 'name', headerName: 'Nome', flex: 1, filterType: 'string' },
        { field: 'email', headerName: 'E-mail', flex: 1, filterType: 'string', },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox>
                        {permissions.change_client && <Tooltip title="Editar cliente">
                            <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton>
                        </Tooltip>}
                        {permissions.delete_client && <Tooltip title="Excluir cliente">
                            <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>
                        </Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        }
    ]
}


const Clients = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        clients: [],
        editRow: null,
        deleteRow: null
    })

    const [table, reloadData] = TableHook()

    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o cliente ${state.deleteRow.name} - ${state.deleteRow.email}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditClientModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
            }
            <Box className='information-table'>
                <Box className='user-table'>
                    <Table
                        rows={state.clients}
                        columns={useMemo(() => columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        toolbar
                        slots={{
                            addComponent: () => user.permissions.add_client ? setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        getURL={url}
                        updateRows={(rows) => setState({ 'clients': rows })}
                        innerRef={table}
                        mobile={true}
                        mobileProps={
                            {
                                title: ['name'],
                                secondary: [
                                    ['cpf_cnpj'],
                                    ['email'],
                                    ['phone_number']
                                ]
                            }
                        }
                    />
                </Box>
            </Box>
        </>
    )
}

export default Clients