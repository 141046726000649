import { api } from "../../services/api";
import { NumbersOnly } from "./IntegerOnly";

const cepLoad = async (cep) => {
    const res = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
    if (res.status === 200) {
        return res
    }
}

export const handleCepComplete = async (e, setState, state) => {
    const cep = NumbersOnly(e.target.value);
    if (e.type === 'change' && cep.length === 8) {
        const addressData = await cepLoad(cep);
        if (!addressData.data.erro === true) {
            setState({
                street: addressData.data.logradouro,
                neighborhood: addressData.data.bairro,
                city: addressData.data.localidade,
                uf: addressData.data.uf,
            });
        }
    }
}


