import React, { useContext, useEffect, useReducer, useMemo } from "react";

import { Box, IconButton, Tooltip } from "@mui/material";
import { api } from "../../../services/api";
import toast from "react-hot-toast";
import { getSession } from "../../../contexts/auth";
import { lockedReducer } from "../../Utils/reducers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../../Utils/ConfirmationDialog";
import { externalComponent } from "../../AppRoutes";
import { maskedTable } from "../../Utils/MaskedInput";
import EditServiceModal from "./Edit-service-modal";
import Table, { TableHook } from "../../Table";
import { ResponsiveActionBox } from "../../Table/mobileTable";

const url = "/service/";

const submitDelete = async (data, setState, getData, setLoader) => {
  setLoader(true);
  const res = await api.delete(`${url}${data.id}/`);
  setLoader(false);
  if (res.status === 204) {
    toast.success("Deletado com sucesso");
    setState({ deleteRow: false });
    getData();
    return;
  }
  console.error("Falha ao deletar");
};

const columns = (setState, permissions) => {
  return [
    {
      field: "identifier",
      headerName: "Identificador",
      flex: 1,
      filterType: "number",
    },
    { field: "name", headerName: "Nome", flex: 1, filterType: "string" },
    {
      field: "value",
      headerName: "Valor",
      flex: 1,
      filterType: "number",
      valueGetter: ({ value }) => {
        return maskedTable["value"](value);
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      filterable: false,
      renderCell: (props) => (
        <ResponsiveActionBox>
          {permissions.change_service && (
            <Tooltip title="Editar seviço">
              <IconButton onClick={() => setState({ editRow: props.row })}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {permissions.delete_service && (
            <Tooltip title="Excluir serviço">
              <IconButton onClick={() => setState({ deleteRow: props.row })}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </ResponsiveActionBox>
      ),
    },
  ];
};

export const loadData = async (setState, permission, setLoader) => {
  if (!permission) {
    toast.error("Você não tem permissão para ver os serviços");
    return;
  }
  const requests = [api.get(url + "documents/")];
  const [documents] = await Promise.all(requests);
  const result = {};
  if (documents.status === 200) result.documents = documents.data;
  setState({ ...result });
};

const Service = () => {
  const { setLoader } = useContext(externalComponent);

  const user = getSession();

  const [state, setState] = useReducer(lockedReducer, {
    rows: [],
    editRow: null,
    deleteRow: null,
    documents: [],
  });

  useEffect(() => {
    loadData(setState, user.permissions.view_service, setLoader);
  }, [setLoader, user.permissions.view_service]);

  const [table, reloadTable] = TableHook();

  const reloadData = () => {
    loadData(setState, user.permissions.view_service, setLoader);
    reloadTable();
  };

  return (
    <>
      {state.deleteRow && (
        <ConfirmationDialog
          content={`Deseja excluir o serviço ${state.deleteRow.name} - ${state.deleteRow.identifier}?`}
          handleClose={() => setState({ deleteRow: null })}
          onConfirm={() =>
            submitDelete(state.deleteRow, setState, reloadData, setLoader)
          }
        />
      )}
      {state.editRow && (
        <EditServiceModal
          data={state.editRow}
          documents={state.documents}
          handleClose={() => setState({ editRow: null })}
          reloadData={reloadData}
        />
      )}
      <Box className="information-table">
        <Box className="user-table">
          <Table
            rows={state.rows}
            columns={useMemo(
              () => columns(setState, user.permissions),
              [user.permissions]
            )}
            pagination
            density="compact"
            slots={{
              returnComponent: ()=> window.backtobase(),
              addComponent: () =>
                user.permissions.add_service ? setState({ editRow: {} }) : null,
              filterComponent: true,
              columnComponent: true,
            }}
            toolbar
            getURL={url}
            updateRows={(rows) => setState({ rows: rows })}
            innerRef={table}
            mobile={true}
            mobileProps={{
              title: ["document"],
              secondary: [["name"], ["value"]],
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Service;
