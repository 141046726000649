import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EngineeringIcon from '@mui/icons-material/Engineering'
import EditIcon from '@mui/icons-material/Edit'
import { api } from '../../services/api'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Tooltip, IconButton } from '@mui/material'
import React, { useContext, useReducer, useMemo } from 'react'
import Table, { TableHook } from '../Table';
import { getSession } from '../../contexts/auth'
import { externalComponent } from '../AppRoutes'
import ColorButton from '../Buttons/ColorButton'
import { lockedReducer } from '../Utils/reducers'
import './styles.css'
import { ResponsiveActionBox } from '../Table/mobileTable'
import ConfirmationDialog from '../Utils/ConfirmationDialog'
import toast from 'react-hot-toast'
import EditServiceOrderModal from './Edit-serviceorder-modal'

const url = '/serviceorder/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const columns = (setState, permissions) => {
    return [
        { field: 'service_number', headerName: 'Número', flex: 1, filterType: 'number' },
        { field: 'owner', headerName: 'Proprietário', flex: 1, filterType: 'string' },
        { field: 'brand_model', headerName: 'Veículo', flex: 1, filterType: 'string', },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox>
                        {permissions.change_serviceorder && <Tooltip title="Editar ordem de serviço">
                            <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton>
                        </Tooltip>}
                        {permissions.delete_serviceorder && <Tooltip title="Excluir ordem de serviço">
                            <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>
                        </Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        }
    ]
}

const Cockpit = () => {
    const user = getSession()

    const { setLoader } = useContext(externalComponent)


    const [state, setState] = useReducer(lockedReducer, {
        services: [],
        editServiceOrder: null,
        showFilters: false,
        editRow: null,
        deleteRow: null

    })

    const [table, reloadData] = TableHook()

    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir ${state.deleteRow.owner} ?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditServiceOrderModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
            }
            {state.editServiceOrder &&
                <EditServiceOrderModal
                    data={state.editServiceOrder}
                    handleClose={() => setState({ editServiceOrder: null })}
                    reloadData={reloadData}
                />
            }

            <Box className='cockpitmenu'>
                <Box className='left' sx={{ display: 'flex', gap: '1rem' }}>
                    <ColorButton disabled={!user.permissions.add_serviceorder}
                        onClick={() => setState({ editServiceOrder: {} })}>
                        Iniciar ordem de serviço
                    </ColorButton>
                </Box>

                <Box className='information-table'>
                    <Box className='user-table'>
                        <Table
                            rows={state.services}
                            columns={useMemo(() => columns(setState, user.permissions), [user.permissions])}
                            pagination
                            density='compact'
                            toolbar
                            getURL={url}
                            updateRows={(rows) => setState({ 'services': rows })}
                            innerRef={table}
                            mobile={true}
                            mobileProps={
                                {
                                    title: ['number'],
                                    secondary: [
                                        ['name'],
                                        ['brand_model']
                                    ]
                                }
                            }
                        />
                    </Box>
                </Box>
            </Box>

        </>
    )

}
export default Cockpit