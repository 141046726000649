import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, Select, InputLabel, FormControl, MenuItem, Autocomplete } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { capitalize } from '../../Utils/functions'
import { NumericFormatInput } from '../../Utils/MaskedInput';
import IntegerOnly from '../../Utils/IntegerOnly';
import CircularProgress from '@mui/material/CircularProgress';
import timeoutList from '../../Utils/TimeoutList';

const url = '/service/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Serviço ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Serviço não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const EditServiceModal = ({ data, handleClose, reloadData, documents }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        name: '',
        deadline: 0,
        documents: [],
        bait_mapper: false,
        ...data,
        id: data.id ? data.id : 0,
        value: data.value ? String(data.value) : '',
        plagues: data.plagues_serialized || [],
    })

    const [helpers, setHelpers] = useReducer(lockedReducer, { 
        loaded_plagues: []
    })

    const validData = validateData(state)

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Serviço`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name}
                                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="valor"
                                    type="text"
                                    label="Valor"
                                    value={state.value}
                                    onChange={(e) => { setState({ 'value': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatInput
                                    }}
                                />
                                <TextField
                                    id="deadline"
                                    type="text"
                                    label="Prazo Assist. Técnica"
                                    size='small'
                                    value={state.deadline}
                                    onChange={(e) => { setState({ 'deadline': IntegerOnly(e.target.value) }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                <InputLabel sx={{ flex: 1 }}>Mapeamento de iscas</InputLabel>
                                <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre', flex: .5 }}>
                                    <ColorButton reverse={!state.bait_mapper} onClick={() => setState({ bait_mapper: true })}>Sim</ColorButton>
                                    <ColorButton reverse={state.bait_mapper} onClick={() => setState({ bait_mapper: false })}>Não</ColorButton>
                                </Box>
                            </Box>
                            <FormControl sx={{ minWidth: 120 }} size='small' >
                                <InputLabel id="select-documents-label">Documentos</InputLabel>
                                <Select
                                    multiple
                                    max={1}
                                    labelId="select-documents-label"
                                    id="select-documents"
                                    label="Documentos"
                                    value={state.documents}
                                    onChange={(e) => { setState({ 'documents': e.target.value }) }}
                                >
                                    {documents.map((each) =>
                                        <MenuItem value={each.id}>{capitalize(each.name)}</MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Autocomplete
                                    multiple
                                    sx={{ flex: 1 }}
                                    id={'autocompletebox-plagues'}
                                    value={state.plagues}
                                    options={helpers.loaded_plagues.filter(each => !state.plagues?.map(e => e.id).includes(each.id))}
                                    onChange={(e, selectedPlagues) => { setState({ plagues: selectedPlagues }) }}
                                    autoHighlight
                                    handleHomeEndKeys
                                    clearText='Limpar'
                                    noOptionsText='Digite para pesquisar Serviços'
                                    loading={state.smallLoading}
                                    ChipProps={{ size: 'small' }}
                                    filterSelectedOptions
                                    onOpen={() => helpers.loaded_plagues.length === 0 && timeoutList('', 'service/plagues/', 'loaded_plagues', 'name', setHelpers)}
                                    onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'service/plagues/', 'loaded_plagues', 'name', setHelpers) }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            id={'autocompleteinput'}
                                            type="text"
                                            sx={{ backgroundColor: 'white' }}
                                            label="Pragas"
                                            size="small"
                                            fullWidth={true}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    }
                                />
                            </Box >
                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditServiceModal